import React from "react";
import { useState } from "react";
import { Wrapper, VirtualMobile, ButtonWrapper, ListWrapper, Separator, FormFieldWrapper,LandingSlider,PhotosWrapper, CarrouselWrapper2, ImageWrapper, ArtistBioWrapper,ArtistImage, ArtistBio,Atelier,GifTile,CacheCache,Quote,QuoteWrapper, Slide, SlideImage,ImageArtistWrapper, ArtistBioText} from "./Reminiscence.module.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import FELIXHANAPORTRAIT from '../../../../res/Reminiscence/felix & hannah/portrait.jpg'
import DJABRILPROTRAIT from '../../../../res/Reminiscence/djabril/portrait.jpg'
import CLEMENCEPROTRAIT from '../../../../res/Reminiscence/clemence/portrait.jpg'
import HAKIMPROTRAIT from '../../../../res/Reminiscence/hakim/portrait.jpg'
import FHOeuvre1 from '../../../../res//Reminiscence/felix & hannah/1.png'
import FHOeuvre2 from '../../../../res//Reminiscence/felix & hannah/2.png'
import FHOeuvre3 from '../../../../res//Reminiscence/felix & hannah/3.png'
import DjabrilOeuvre1 from '../../../../res//Reminiscence/djabril/1.png'
import DjabrilOeuvre2 from '../../../../res//Reminiscence/djabril/2.png'
import DjabrilOeuvre3 from '../../../../res//Reminiscence/djabril/3.png'
import ClemenceOeuvre1 from '../../../../res//Reminiscence/clemence/1.jpg'
import ClemenceOeuvre2 from '../../../../res//Reminiscence/clemence/2.jpg'
import ClemenceOeuvre3 from '../../../../res//Reminiscence/clemence/3.jpg'
import HakimOeuvre1 from '../../../../res//Reminiscence/hakim/1.png'
import HakimOeuvre2 from '../../../../res//Reminiscence/hakim/2.png'
import HakimOeuvre3 from '../../../../res//Reminiscence/hakim/3.png'
import REMINISCENCE from "../../../../res/Reminiscence/slide.png"
import REMINISCENCETILE from "../../../../res/Reminiscence/tile.png"

import Title from "../../../../components/Title";


// import FeedInstagram from "../../../../components/FeedInstagram";


const Landing = () => {


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const responsiveArtist = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };


  const oeuvresFH = 
    {
      photos: [
        { src: FHOeuvre1, artist: "Félix Taburet", title: "Polemos panton pater etsi, 2022", dimension: "210 x 165 cm", copyright: "© Félix Taburet et Hannah Becquante", style: "Marbre, Mortier, Pigments"},
        { src: FHOeuvre2, artist: "Félix Taburet", title: "Cris sans écho, signes de mort, temps hors mémoire, 2020", dimension: "43 x 50 x 45 cm", copyright: "© Félix Taburet et Hannah Becquante", style: "Mortier, Pigments, suie"},
        { src: FHOeuvre3, artist: "Félix Taburet et Hannah Becquante", title: "Jusqu’au feu,, 2022", dimension: "110 x 7 x 80 cm", copyright: "© Félix Taburet et Hannah Becquante", style: "Beton, marbre"},
      ],
  };

  const oeuvresDJABRIL = 
  {
    photos: [
      { src: DjabrilOeuvre1, artist: "Djabril Boukhenaïssi", title: "Portrait de Bogdan, 2020", dimension: "162 x 130 cm", copyright: "© Camélia Chaira", style: "Peinture à l’huile et pastel sur toile"},
      { src: DjabrilOeuvre2, artist: "Djabril Boukhenaïssi", title: "Etude, 2022", dimension: "40 x 50 cm", copyright: "© Camélia Chaira", style: "Peinture à l'huile et pastel sur toile"},
      { src: DjabrilOeuvre3, artist: "Djabril Boukhenaïssi", title: "Camélia dans l’herbe, 2022", dimension: "50 x 40 cm", copyright: "© Camélia Chaira", style: " Peinture à l’huile et pastel sur toile"},
    ],
  };

  const oeuvresCLEMENCE = 
  {
    photos: [
      { src: ClemenceOeuvre1, artist: "Clémence Appie Gbonon", title: "Figure, 2023", dimension: "30 x 34 cm", copyright: "© Clémence Appie Gbonon", style: "Peinture à l’huile sur toile"},
      { src: ClemenceOeuvre2, artist: "Clémence Appie Gbonon", title: "Heat Wave, 2023", dimension: "40 x 30 cm", copyright: "© Clémence Appie Gbonon", style: "Peinture à l’huile et acrylique sur toile"},
      { src: ClemenceOeuvre3, artist: "Clémence Appie Gbonon", title: "Above the Green Carpet, 2023", dimension: "40 x 30 cm", copyright: "© Clémence Appie Gbonon", style: "Peinture à l’huile, acrylique et pastel sur toile"},
    ],
  };
  const oeuvresHAKIM = 
  {
    photos: [
      { src: HakimOeuvre1, artist: "Hakim Sahiri", title: "Joseph II - Gogo no Eiko, 2023", dimension: "91 x 112 cm", copyright: "© Hakim Sahiri", style: "Peinture à l’huile sur toile"},
      { src: HakimOeuvre2, artist: "Hakim Sahiri", title: "La main verte, 2023", dimension: "22 x 27 cm,", copyright: "© Hakim Sahiri", style: "Peinture à l’huile sur toile"},
      { src: HakimOeuvre3, artist: "Hakim Sahiri", title: "Joseph, Nod out, 2022", dimension: "40,5 x 33 cm", copyright: "© Hakim Sahiri", style: "Peinture à l’huile sur toile"},
    ],
  };

  // const [email, setEmail] = useState("");

  const [disp, setDisp] = useState(false);
  const [email, setEmail] = useState("");
  const [result, setResult] = useState(null);
  const [fields, setFields] = useState({
    FNAME: '',
    LNAME: ''
  })

  const swapDisp=()=> {
    setDisp((disp) => !disp);
    console.log("disp === " + disp);
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(email, fields);
    setResult(result);
    console.log("result=>",result.result);
    // but you can do whatever you want
  }

  const handleEmail = async (e) => {
    e.preventDefault();

    setEmail(e.target.value);
    console.log("email=>".email);

  }
  const handleName = async (e) => {
    e.preventDefault();
    setFields({
      FNAME: e.target.value
    });
    console.log("fields=>".fields);
  }
  return (
    <div >
      <div className={GifTile}>
        <img src={REMINISCENCETILE} style={{backgroundSize:"contain", width: "80vw", paddingTop: "25px"}}></img>
        </div>
        <div className={LandingSlider}>
        <Carousel 
        swipeable={false}
        draggable={false}
        slidesToSlide={1}
        responsive={responsive}
        autoPlay={false}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        >
      <div style={{height: "100vh",display: "flex", position: "relative",paddingTop: "35px"}}>
        <img src={REMINISCENCE} style={{backgroundSize:"contain", height: "100vh", width: "100vw"}}></img>
      </div>
    </Carousel>
        </div>
      <div className={Wrapper}>
      <br />
      <div className={CacheCache}>
        <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "60px", lineHeight: "77px", fontWeight: "bold"}}>REMINISCENCE</p>
            <p style={{fontSize: "34px", lineHeight: "43px", marginTop: "-4px"}}>Clémence Appie Gbonon</p>
            <p style={{fontSize: "34px", lineHeight: "43px", marginTop: "-4px"}}>Hakim Sahiri</p>
            <p style={{fontSize: "34px", lineHeight: "43px", marginTop: "-4px"}}>Félix Taburet & Hannah Becquante</p>
            <p style={{fontSize: "34px", lineHeight: "43px", marginTop: "-4px"}}>Djabril Boukhenaïssi</p>
            <p style={{fontSize: "16px", lineHeight: "21px", margin: "9px 0 0"}}>GROUP SHOW</p>
          </div>
          <div style={{display: "flex"}}>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "28px", lineHeight: "36px", marginBottom: "30px", fontWeight: "bold"}}>
            13 Octobre - 18 Novembre 2023
            </p>
          </div>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "28px", lineHeight: "36px", margin: "0 0 19px", fontWeight: "bold"}}>PARIS</p>
            <p style={{fontSize: "16px", lineHeight: "21px"}}>62 RUE DU FAUBOURG <br /> SAINT-HONORÉ</p>
            <p style={{fontSize: "16px", lineHeight: "21px"}}>75008 PARIS FRANCE</p>
          </div>
          </div>
        </div>
        <div style={{display: "flex",width: "50%", paddingBottom: "40px", paddingLeft: "50px"}}>
          {/* <a href="/fr/contactez-nous">
            <p style={{textDecoration: "underline", fontWeight: "bold"}}>CATALOGUE SUR DEMANDE</p>
          </a> */}
          <a  style={{textDecoration: "none"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>COMMUNIQUÉ DE PRESSE</p></a>
          <a href="/fr/contactez-nous" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>CONTACT</p></a>
          <a href="/fr/visite-virtuelle-reminiscence" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>VISITE VIRTUELLE</p></a>
        </div>
      </div>
        <div className={VirtualMobile}>
          <a href="/fr/visite-virtuelle-reminiscence" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>VISITE VIRTUELLE</p></a>
        </div>

        <p style={{fontSize:"18px", textAlign:"justify", lineHeight: "24px"}}>
        Le génie de l’artiste est de rendre vraisemblable les fantômes, d’offrir une vision recomposée, transposée par la plaque sensible de la mémoire, et révélées par l'oeuvre. Dès que le regard se pose, l’espace se crée, et le dialogue entre le visiteur et l’exposition commence : Reminiscence offre un voyage, une déambulation dans l’intime à travers l’imaginaire et la réalité, entre l’obscurité de la nuit et la sensible lueur du jour, aveuglante et suspendue ; entre la conscience tardive des rêves, et leur évanescence, sitôt que leurs fantômes sont révélées. « Le seul véritable voyage, le seul bain de Jouvence, ce ne serait pas d’aller vers de nouveaux paysages, mais d’avoir d’autres yeux, de voir l’univers avec les yeux d’un autre, de cent autres, de voir les cents univers que chacun d’eux voit, que chacun d’eux est. » Marcel Proust, La Prisonnière
       </p>
        <br />
        </div>
            <div style={{backgroundColor: "#ededed", padding: "25px", marginTop: "50px", display: "flex", flexDirection: "column", marginBottom: "50px"}}>
              
              <p style={{alignContent: "center", alignItems:"center", alignSelf: "center"}}>QUE VOUS EVOQUE REMINISCENCE ?</p>
              <br />
              <br />
              <br />
            <p className={Quote} style={{alignSelf: "center"}}>
            Du crépuscule à l’aurore, Réminiscence trace le chemin emprunté par Felix, Hannah, Hakim, Clémence et Djabril. Cette conjoncture invoque un retour à l’esprit par des images, des sensations, des émotions, laissant par la matière une trace inaltérable dans le réel. <strong>Augustin Rothea</strong>
            </p>
          <div className={QuoteWrapper}>
  
          <div style={{fontSize:"18px", textAlign:"justify", margin: "0 auto", width: "80vw",padding: "25px", color: "#272727"}}>
          <p className={Quote}>
            Proust, bien sûr, mais aussi Bergson et Bachelard, qui sont les auteurs qui m’occupent beaucoup en ce moment. La réminiscence est liée à la perte, à la reconstruction, car tout souvenir est le produit d’un assemblage et d’un modelage du passé. C’est à partir de ce mot que j’essaie de travailler, de comprendre ce qui se produit et de construire un tableau à partir de ce qu’on tire de ces expériences phénoménologiques. Il faut citer les belles lignes de Delacroix sur cette question: En réfléchissant sur la fraîcheur des souvenirs, sur la couleur enchantée qu’ils revêtent dans un passé lointain, j’admirais ce travail involontaire de l’âme qui écarte et supprime, dans le ressouvenir de moments agréables, tout ce qui en diminuait le charme, au moment où on les traversait. Je comparais cette espèce d’idéalisation, car c’en est une, à l’effet des beaux ouvrages de l’imagination. Il y a des matériaux qui peuvent nous aider à travailler sur ces questions, et je suis persuadé que le pastel en est un. C’est en partie l’enjeu des tableaux que je présente à Ghost, qui sont des tentatives formelles qui cherchent à évoquer ces notions. <strong>Djabril Boukhenaïssi</strong>
            </p>
            <br />
            <br />
            <p className={Quote}>
            Essayer de comprendre comment les gens se comportent en société, en passant par le langage du corps où, paradoxalement, il donne une part de l’intime dans l’espace public ; c’est ce qui me plait. <strong>Hakim Sahiri</strong>
            </p>
          </div>

          <div style={{fontSize:"18px", textAlign:"justify", margin: "0 auto", width: "80vw",padding: "25px", color: "#272727"}}>
          <p className={Quote}>
            Entre l'image insituable d'une existence antérieure et le souvenir qui nous prend à notre insu pour nous ramener à un certain point de notre vie, le terme de réminiscence ne choisit pas. Reste l'hésitation qui, d'un bord à l'autre de ce mot, maintient l'expression béante entre ses deux rives. Nous ne voulons pas plus parier sur l'une que sur l'autre, jugeant que c'est l'état d'indécision même qu'il faut garder ouvert, pourvu que d'ici la mémoire se ruine par ses marges, efface ses contours, s'indifférencie par osmose entre nous. <strong>Félix Taburet et Hannah Becquante</strong>
            </p>

            <br />
            <br />
            <p className={Quote}>
            La réminiscence, qui semble être au carrefour de nos quatre pratiques, m’évoque une forme de pouvoir : les sensations puissantes, intimes et individuelles sont aussi un lieu de résistance car elles nous sont propres, elles nous appartiennent. Pour moi, le recours au souvenir et au rêve permet de complexifier l’identité du sujet en peinture. <strong>Clémence Gbonon</strong>
            </p>
          </div>
        </div>
        </div>

        
        <div className={Wrapper}>
        <p style={{fontSize:"18px", textAlign:"justify", lineHeight: "24px"}} >
Dans le cadre du programme d’exposition Ghost project, lié à la jeune scène artistique contemporaine, Ghost galerie Paris présente pour son deuxième accrochage, un group show d’artistes français. Réminiscence explore le travail de cinq artistes aux univers distincts et irisés. Perpétuellement à la recherche de nouveaux médiums, ils mêlent l’énigmatique au spirituel ; et, muant le souffle du sommeil en souffle de vie, ils font de leur art l’écho chatoyant de leur cri.
        <br />
        Dès lors, nous devons nous demander : Devons-nous toujours faire revenir le matin ? Car en achevant cette traversée nocturne, nous ne pouvons qu’interroger ces fantasmagories qui hantent le fil de nos pensées. Quelles sont ces figures, à peine reconnaissables ? Réminiscences mémorielles, fantasmes de l’imaginaire, images photographiques ? Certainement toutes à la fois, mais sûrement art, projection onirique transcendée par le papier, la toile, ou la pierre. Et s’opposant constamment au réel, elles sont l’évidence absurde du monde ; le rêve et la vie, et la vie mise en rêve ; échos sempiternels, mouvements de toutes choses. Et la nuit n’en est que le chemin, ou la route, ou le gué. Léon Vuillecard <br />
          </p>
        <br />
        <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              // style={{height: "450px"}}
              src={FELIXHANAPORTRAIT}
            />
            </div>
          </div>
          <div className={ArtistBio}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>Félix Taburet & Hannah Becquante</p>
            {/* <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Né en 1998 à Barcelone, Espagne</p> */}
            <p className={ArtistBioText}>
            La nuit est un voyage qu’Hannah Becquante et Félix Taburet nous font traverser. A entendre son nom, “La Nuit”, tous deux s’émerveillent : c’est le murmure du jour qui s’efface, livrant avec lui son absence. Dans cette poétique - une mosaïque effritée, des mains amputées, une tête sans corps - partout règne le vide, des paysages aux espaces nocturnes, figés, immobiles et silencieux, ou qui ne le sont pas ; car Félix et Hannah mettent en garde : ne figez pas une nature, ne scellez pas une image, mais laissez vous duper, car ce ne sont que des fantômes.
              </p>
              <br />
            {/* <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueAna}>Télécharger le catalogue des oeuvres</a> */}
          </div>
        </div>
        <div className={PhotosWrapper}>
        <div className={CarrouselWrapper2}>
        <Title title={"Sélection"} />
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsiveArtist}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={false}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {oeuvresFH.photos.map((image) => (
              <div className={Slide}>
                <div className={SlideImage}>
                <img
                  className={ImageWrapper}
                  style={{objectFit: "contain"}}
                  // key={image.name}
                  // alt={image.name}
                  src={image.src}
                />
                </div>
                <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
                <strong>{image.artist}</strong>
                <i>{image.title}</i>
                <p>{image.style}</p>
                <p>{image.dimension}</p>
                <p>{image.copyright}</p>
                </div> 
                </div>
            ))}
          </Carousel>
        </div>
      </div>
      </div>
      <div className={Wrapper}>
      <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{width: "300px", height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              style={{objectPosition: "0 0"}}
              src={CLEMENCEPROTRAIT}
            />
            </div>
          </div>
          <div className={ArtistBio} style={{paddingTop: "100px"}}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>Clémence Appie Gbonon</p>
            {/* <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Né en 1996 à Paris, France</p> */}
            <p className={ArtistBioText}>
            C’est ce même rêve qui attire chez Clémence Gbonon ; état consubstantiel à son oeuvre. Elle tente d’oublier le conscient pour permettre aux images qui s’écoulent de prendre forme. De là, naît le torse masculin, symbole d’une masculinité vulnérable. Une forme figure, réceptacle d’une multitude de projections, mais avant tout une figure de peinture, puissante, tactile et sensuelle. Un onirisme qui se condense dans l’ultime souvenir de la toile ; un mystère, qui se matérialise en scènes énigmatiques, et qui impose un au-delà à l'oeuvre : la figure naissante de l’incomplétude de l’être.
            </p>
            <br />
          {/* <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueMathias}>Télécharger le catalogue des oeuvres</a> */}
          </div>
        </div>
        <div className={PhotosWrapper}>
        <div className={CarrouselWrapper2}>
        <Title title={"Sélection"} />
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsiveArtist}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={false}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {oeuvresCLEMENCE.photos.map((image) => (
              <div className={Slide}>
                <div className={SlideImage}>

                  <img
                    className={ImageWrapper}
                    style={{objectFit: "contain"}}
                    // key={image.name}
                    // alt={image.name}
                    src={image.src}
                  />
                </div>
                <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
                <strong>{image.artist}</strong>
                <i>{image.title}</i>
                <p>{image.style}</p>
                <p>{image.dimension}</p>
                <p>{image.copyright}</p>
                </div> 
                </div>
            ))}
          </Carousel>
    </div>
    </div>
    <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{width: "300px", height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              style={{objectPosition: "0 0"}}
              src={DJABRILPROTRAIT}
            />
            </div>
          </div>
          <div className={ArtistBio} style={{paddingTop: "100px"}}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>Djabril Boukhenaïssi</p>
            {/* <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Né en 1996 à Paris, France</p> */}
            <p className={ArtistBioText}>
            Alors, Djabril Boukhenaïssi offre la tentation du souvenir, son heuristique, emportant avec lui le ressac des images qui l'accompagnent. Là, devant ces toiles effacées se dévoile le mémoriel ; va-et-vient incessants de fantômes à demi-peint. Il module sur la réminiscence des souvenirs et l’essence fragile de la mémoire ; ce ne sont que des pensées. Mais le sont-elles réellement ? Est-ce la mythologie perpétuelle de l’être qui existe en chacun ? Qui se crée, se déforme et s’efface, pour tout recommencer. Les tableaux de Djabril sont comme des réédifications qui s’éveillent et meurent à chaque instant. Mais Djabril interroge toujours : Que reste t-il de nos souvenirs ? “L’idée de la disparition de la nuit m’a toujours fasciné. Dans le monde réel, la nuit périclite peu à peu et, périclitant avec elle, l’objet métaphorique de la nuit finira inexorablement par s’effacer lui aussi, jusqu’à disparaître.”
            </p>
            <br />
          {/* <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueMathias}>Télécharger le catalogue des oeuvres</a> */}
          </div>
        </div>
        <div className={PhotosWrapper}>
        <div className={CarrouselWrapper2}>
        <Title title={"Sélection"} />
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsiveArtist}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={false}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {oeuvresDJABRIL.photos.map((image) => (
              <div className={Slide}>
                <div className={SlideImage}>

                  <img
                    className={ImageWrapper}
                    style={{objectFit: "contain"}}
                    // key={image.name}
                    // alt={image.name}
                    src={image.src}
                  />
                </div>
                <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
                <strong>{image.artist}</strong>
                <i>{image.title}</i>
                <p>{image.style}</p>
                <p>{image.dimension}</p>
                <p>{image.copyright}</p>
                </div> 
                </div>
            ))}
          </Carousel>
    </div>
    </div>
    <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{width: "300px", height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              style={{objectPosition: "0 0"}}
              src={HAKIMPROTRAIT}
            />
            </div>
          </div>
          <div className={ArtistBio} style={{paddingTop: "100px"}}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>Hakim Sahiri</p>
            {/* <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Né en 1996 à Paris, France</p> */}
            <p className={ArtistBioText}>
            A l’heure de la nuit glissent les navires en sommeil. Hakim Sahiri, par son regard, capture des sujets transis. C’est l’inemuri qui les accompagne. L’inemuri, c’est cet état de dormance qui n’est que parce que l’un s’abandonne, et que l’autre s’interroge. Il y a alors dialogue, entre celui qui observe, curieux, et le sommeil de l’abandonné, quintessence de l’intime. Hakim offre alors une contemplation poétique des corps endormis, ou plutôt, la vulnérabilité de l’être qui s’offre tout entier, à l’inexorable indifférence du monde - à quoi rêve-t-il ?
            </p>
            <br />
          {/* <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueMathias}>Télécharger le catalogue des oeuvres</a> */}
          </div>
        </div>
        <div className={PhotosWrapper}>
        <div className={CarrouselWrapper2}>
        <Title title={"Sélection"} />
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsiveArtist}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={false}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {oeuvresHAKIM.photos.map((image) => (
              <div className={Slide}>
                <div className={SlideImage}>

                  <img
                    className={ImageWrapper}
                    style={{objectFit: "contain"}}
                    // key={image.name}
                    // alt={image.name}
                    src={image.src}
                  />
                </div>
                <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
                <strong>{image.artist}</strong>
                <i>{image.title}</i>
                <p>{image.style}</p>
                <p>{image.dimension}</p>
                <p>{image.copyright}</p>
                </div> 
                </div>
            ))}
          </Carousel>
    </div>
    </div>
    </div>
      <br />
          <br />
          <br />
      </div> 
  );
};

export default Landing;